<azavista-autocomplete-input-field #autocomplete itemsBoxLabel="{{'RECENTLY_EDITED' | translate }}"
    (autocompleteSearchTextChanged)="onEventSearchTextChanged($event)" (gainedFocus)="onFocus()"
    [options]="eventFieldOptions" [field]="eventField"
    [value]="eventFieldValue" [fullWidth]="true"
    [itemTemplate]="itemTemplate"
    class="full-width quick-access-search__autocomplete-panel"
    placeholder="{{'SEARCH_EVENTS' | translate}}">
</azavista-autocomplete-input-field>

<ng-template #itemTemplate let-item="item">
    <div class="full-width quick-access-search__autocomplete-panel__item-event">
        {{ item.name }}
    </div>
</ng-template>
