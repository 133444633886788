import { Injectable } from '@angular/core';

import { SearchParams } from '@azavista/advanced-search';
import {
    AzavistaApiService, IGetGlobalSettingRequest, ISaveGlobalSettingRequest, IGetParentSettingRequest,
    ISaveParentSettingRequest, IGetGlobalSettingResponse, IGetParentSettingResponse,
} from '@azavista/servicelib';
import { IAdvancedSearchData, IAdvancedFilter, IAdvancedFilterCriteria, IField, ITableSettingsChangedData } from '@azavista/components/shared';
import { IModifiedTableColumnsData } from '@azavista/components/table';
import { IColumnsListSetting, IFieldExportList } from './shared.interfaces';
import { SharedService } from './shared.service';
import { SettingType, SettingEntityType, SettingParentType, SettingEntityTypeExtra } from './enums';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    constructor(private apiSvc: AzavistaApiService, private sharedSvc: SharedService) {
    }

    async saveSelectedViewSettings(parentType: SettingEntityType, parentEntityId: string, viewId: string): Promise<void> {
        const setting = { active_setting_id: viewId || '' };
        const req: ISaveParentSettingRequest = {
            parentType: parentType, parentEntityId: parentEntityId, entityType: parentType, settingType: SettingType.viewindex,
            setting: setting
        };
        await this.apiSvc.saveParentSetting(req);
    }

    async getSelectedViewSettings(parentType: SettingEntityType, parentEntityId: string): Promise<IGetParentSettingResponse> {
        const req: IGetParentSettingRequest = {
            parentType: parentType, parentEntityId: parentEntityId, entityType: parentType, settingType: SettingType.viewindex
        };
        const res = await this.apiSvc.getParentSetting(req);
        return res;
    }

    async getEventParticipantFieldsExportList(eventId: string): Promise<IFieldExportList> {
        const req: IGetParentSettingRequest = {
            entityType: SettingEntityType.participant, parentEntityId: eventId, parentType: SettingEntityType.event,
            settingType: SettingType.listingExport
        };
        const exportList = await this.getParentSetting(req);
        if (!exportList || !exportList.setting) {
            return { selectedFieldNames: [] } as IFieldExportList;
        }
        return exportList.setting as IFieldExportList;
    }

    async saveEventParticipantFieldsExportList(eventId: string, fieldExportList: IFieldExportList): Promise<void> {
        const req: ISaveParentSettingRequest = {
            entityType: SettingEntityType.participant, parentEntityId: eventId, parentType: SettingEntityType.event,
            settingType: SettingType.listingExport, setting: fieldExportList
        };
        await this.apiSvc.saveParentSetting(req);
    }

    getAdvancedFilters(tableData: ITableSettingsChangedData, advSearchData: IAdvancedSearchData): IAdvancedFilter[] | null {
        const advSearch = tableData.advancedSearch;
        if (!advSearch) {
            // No changes in advanced search filters
            return null;
        }
        const hasChanges = advSearch.createdFilter || advSearch.deletedFilter || advSearch.savedFilter;
        if (!hasChanges) {
            // No changes in advanced search filters
            return null;
        }
        let advancedFilters: IAdvancedFilter[] = advSearchData.advancedFilters;
        if (advSearch.deletedFilter) {
            advancedFilters = advancedFilters.filter(x => x.id !== advSearch.deletedFilter.id);
        }
        // Remove filters with no criteria
        advancedFilters = advancedFilters.filter(x => x.criteria && x.criteria.length > 0);
        return advancedFilters;
    }

    async getGlobalAdvancedSearchFilters<Type extends SettingEntityType>(
        entityType: Type,
        ...extra: (Type extends keyof SettingEntityTypeExtra ? [SettingEntityTypeExtra[Type]] : [])
    ): Promise<IAdvancedFilter[]> {
        let advancedFilters: IAdvancedFilter[] = [];
        try {
            const req: IGetGlobalSettingRequest = {
                entityType: this.getGlobalSettingsFormattedEntityType(entityType, ...extra),
                settingType: SettingType.advancedSearch
            };
            const res = await this.getGlobalSetting(req);
            advancedFilters = res.setting.advancedFilters;
        } catch (err) {

        }
        return advancedFilters;
    }

    async getParentAdvancedSearchFilters(
        parentType: SettingParentType, parentEntityId: string, entityType: SettingEntityType
    ): Promise<IAdvancedFilter[]> {
        let advancedFilters: IAdvancedFilter[] = [];
        try {
            const req: IGetParentSettingRequest = {
                parentType: parentType, parentEntityId: parentEntityId, entityType: entityType,
                settingType: SettingType.advancedSearch
            };
            const res = await this.getParentSetting(req);
            advancedFilters = res.setting.advancedFilters;
        } catch (err) {

        }
        return advancedFilters;
    }

    createSearchParamsFilters(advancedFilters: IAdvancedFilter[]): ISearchParamsFilter[] {
        const filters: ISearchParamsFilter[] = [];
        for (const filter of advancedFilters) {
            const searchParam = this.sharedSvc.createSearchParams(filter.criteria);
            filters.push({ id: filter.id, name: filter.name, searchParams: searchParam });
        }
        return filters;
    }

    async saveParentAdvancedSearchFilters(
        tableData: ITableSettingsChangedData, advSearchData: IAdvancedSearchData,
        parentType: SettingParentType, parentEntityId: string, entityType: SettingEntityType
    ): Promise<void> {
        let advancedFilters = this.getAdvancedFilters(tableData, advSearchData);
        if (!advancedFilters) {
            return;
        }
        advancedFilters = advancedFilters.filter(x => !x.isTemporary);
        const searchParamsFilters = this.createSearchParamsFilters(advancedFilters);
        const setting: IAdvancedSearchSetting = { advancedFilters: advancedFilters, searchParamsFilters: searchParamsFilters };
        const req: ISaveParentSettingRequest = {
            parentType: parentType, parentEntityId: parentEntityId, settingType: SettingType.advancedSearch,
            entityType: entityType, setting: setting
        };
        await this.apiSvc.saveParentSetting(req);
    }

    private getGlobalSettingsFormattedEntityType<Type extends SettingEntityType>(
        entityType: Type,
        ...[extra]: (Type extends keyof SettingEntityTypeExtra ? [SettingEntityTypeExtra[Type]] : never[])
    ) {
        return extra ? `${entityType}_${Object.entries(extra).reduce((combined, [key, value]) => {
            return `${combined}_${key}-${value}`;
        }, '')}` : entityType;
    }

    async saveGlobalAdvancedSearchFilters<Type extends SettingEntityType>(
        tableData: ITableSettingsChangedData,
        advSearchData: IAdvancedSearchData,
        entityType: Type,
        ...extra: (Type extends keyof SettingEntityTypeExtra ? [SettingEntityTypeExtra[Type]] : [])
    ): Promise<void> {
        const advancedFilters = this.getAdvancedFilters(tableData, advSearchData);
        if (!advancedFilters) {
            return;
        }
        // TODO: Remove any unnecessary properties from the field ?
        //       Like schema etc. Because the field could be changed in the future but the saved setting
        //       will still contain old values of let's say label, schema.type, schema.enum etc.
        // const sanitizedAdvFilters = this.sanitizeAdvancedFilters(advancedFilters);
        const sanitizedAdvFilters = advancedFilters;
        const searchParamsFilters = this.createSearchParamsFilters(advancedFilters);
        const setting: IAdvancedSearchSetting = { advancedFilters: sanitizedAdvFilters, searchParamsFilters: searchParamsFilters };
        const req: ISaveGlobalSettingRequest = {
            entityType: this.getGlobalSettingsFormattedEntityType(entityType, ...extra),
            setting: setting,
            settingType: SettingType.advancedSearch
        };
        await this.apiSvc.saveGlobalSetting(req);
    }

    async saveGlobalColumnsSettings<Type extends SettingEntityType>(
        entityType: Type,
         data: IModifiedTableColumnsData,
        ...extra: (Type extends keyof SettingEntityTypeExtra ? [SettingEntityTypeExtra[Type]] : [])
    ): Promise<void> {
        const setting: IColumnsListSetting = { columnIdsOrder: data.selectedIds, columnsIdsWithWidths: data.columnsWidths };
        const req: ISaveGlobalSettingRequest = {
            entityType: this.getGlobalSettingsFormattedEntityType(entityType, ...extra), settingType: SettingType.listingColumns, setting: setting
        };
        await this.apiSvc.saveGlobalSetting(req);
    }

    async saveParentColumnsSettings(
        parentType: SettingParentType, parentEntityId: string, entityType: SettingEntityType,
        data: IModifiedTableColumnsData
    ): Promise<void> {
        const setting: IColumnsListSetting = { columnIdsOrder: data.selectedIds, columnsIdsWithWidths: data.columnsWidths };
        const req: ISaveParentSettingRequest = {
            parentType: parentType, parentEntityId: parentEntityId, entityType: entityType, settingType: SettingType.listingColumns,
            setting: setting
        };
        await this.apiSvc.saveParentSetting(req);
    }

    async getGlobalColumnsSettings<Type extends SettingEntityType>(
        entityType: Type,
        allowedIds: string[],
        defaultIds: string[],
        ...extra: (Type extends keyof SettingEntityTypeExtra ? [SettingEntityTypeExtra[Type]] : [])
    ): Promise<IColumnsListSetting> {
        let columnIds: string[] = [];
        let res: IRegressionGetSettingResponse;
        try {
            const req: IGetGlobalSettingRequest = {
                entityType: this.getGlobalSettingsFormattedEntityType(entityType, ...extra),
                settingType: SettingType.listingColumns
            };
            res = await this.getGlobalSetting(req);
            const setting = res.setting as IColumnsListSetting;
            // We must filter ids that are not part of the table columns
            columnIds = this.sharedSvc.filterNotAllowed(setting.columnIdsOrder, allowedIds);
        } catch (err) {
            // Returns HTTP 404 in case the setting is not found
        }
        if (!columnIds || !columnIds.length) {
            columnIds = defaultIds;
        }
        const result = {} as IColumnsListSetting;
        result.columnIdsOrder = columnIds;
        result.columnsIdsWithWidths = (res && res.setting) ? (res.setting as IColumnsListSetting).columnsIdsWithWidths || [] : [];
        return result;
    }

    async getParentColumnsOrder(
        parentType: SettingParentType, parentEntityId: string,
        entityType: SettingEntityType, allowedIds: string[], defaultIds: string[]
    ): Promise<IColumnsListSetting> {
        let columnIds: string[] = [];
        let res: IRegressionGetSettingResponse;
        try {
            const req: IGetParentSettingRequest = {
                parentType: parentType, parentEntityId: parentEntityId,
                entityType: entityType, settingType: SettingType.listingColumns
            };
            res = await this.getParentSetting(req);
            const setting = res.setting as IColumnsListSetting;
            // We must filter ids that are not part of the table columns
            columnIds = this.sharedSvc.filterNotAllowed(setting.columnIdsOrder, allowedIds);
        } catch (err) {
            // Returns HTTP 404 in case the setting is not found
        }
        if (!columnIds || !columnIds.length) {
            columnIds = defaultIds;
        }
        const result = {} as IColumnsListSetting;
        result.columnIdsOrder = columnIds;
        result.columnsIdsWithWidths = (res && res.setting) ? (res.setting as IColumnsListSetting).columnsIdsWithWidths || [] : [];
        return result;
    }

    sanitizeAdvancedFilters(advFilters: IAdvancedFilter[]): IAdvancedFilter[] {
        const sanitized: IAdvancedFilter[] = [];
        for (const advFilter of advFilters) {
            sanitized.push(this.sanitizeAdvancedFilter(advFilter));
        }
        return sanitized;
    }

    sanitizeAdvancedFilter(advFilter: IAdvancedFilter): IAdvancedFilter {
        const sanitized: IAdvancedFilter = {
            id: advFilter.id, name: advFilter.name
        } as IAdvancedFilter;
        const sanitizedCriteria: IAdvancedFilterCriteria[] = [];
        for (const criteria of advFilter.criteria) {
            sanitizedCriteria.push({
                operator: criteria.operator, value: criteria.value,
                field: { id: criteria.field.id, name: criteria.field.name } as IField
            });
        }
        sanitized.criteria = sanitizedCriteria;
        return sanitized;
    }

    async getParentSetting(req: IGetParentSettingRequest): Promise<IRegressionGetSettingResponse> {
        const res: IGetParentSettingResponse = await this.apiSvc.getParentSetting(req);
        return res.settings[0];
    }

    async getGlobalSetting(req: IGetGlobalSettingRequest): Promise<IRegressionGetSettingResponse> {
        const res: IGetGlobalSettingResponse = await this.apiSvc.getGlobalSetting(req);
        return res.settings[0];
    }

    async getGlobalDashboard(req: IGetGlobalSettingRequest): Promise<IGetGlobalSettingResponse> {
        const res: IGetGlobalSettingResponse = await this.apiSvc.getGlobalSetting(req);
        return res;
    }

    async getParentDashboard(req: IGetParentSettingRequest): Promise<IGetParentSettingResponse> {
        const res: IGetGlobalSettingResponse = await this.apiSvc.getParentSetting(req);
        return res;
    }
}

export interface IRegressionGetSettingResponse {
    setting: any;
}

interface IAdvancedSearchSetting {
    advancedFilters: IAdvancedFilter[];
    searchParamsFilters: ISearchParamsFilter[];
}

interface ISearchParamsFilter {
    id: string;
    name: string;
    searchParams: SearchParams;
}
